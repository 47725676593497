import React, { useState } from "react";
import './project.css';
import project1_img from '../../../assets/images/project1.png';
import project2_img from '../../../assets/images/project2.png';
import project_img from '../../../assets/images/project-image-1.jpg';
import project_img_3 from '../../../assets/images/project-image-3.jpg';
import project_img_4 from '../../../assets/images/project-image-4.jpg';
import { Link } from "react-router-dom";
import ProjectRef from "../../ProjectRef/projectRef";


const Work_Project = () => {
    const [data, setData] = useState({
        exp1: "I have over 1 year of professional experience as",
        exp2: "a UX/UI Designer. My projects include my",
        exp3: "professional as well as personal case studies.",
        desc1: "are some of my",
        desc2: "personal as well as professional",
        desc3: "projects that I have worked on."
    })
    return(
        <>
        {/* <div className="work-project-container">
            <div className="work-project-wrapper">
                <div className="work-project-experience-wrapper">
                    <div className="work-project-experience">
                        <div className="work-project-exp-1">
                            <h1 className="work-project-exp-text">{data.exp1}</h1>
                        </div>
                        <div className="work-project-exp-2">
                            <h1 className="work-project-exp-text">{data.exp2}</h1>
                        </div>
                       
                    </div>

                   
                </div>
                <hr className="project-line-separation" />
                <div className="work-project-details-wrapper">
                    <div className="work-project-details">
                        <div className="work-project-detail">
                            <div className="work-project-detail-explanation">
                                <div className="work-project-detail-exp-wrapper">
                                    <p><span className="work-project-detail-These">These</span> {data.desc1}</p>
                                    <p>{data.desc2}</p>
                                    <p>{data.desc3}</p>
                                </div>
                            </div>
                            <div className="work-project-detail-img">
                                <img  className="work-project-detail-img-1"  src={project1_img} />
                            </div>
                        </div>
                        <div className="work-project-detail">
                            <div  className="work-project-detail-img">
                            <Link className="nav-Link-react-router" to="/"><img className="work-project-detail-img-2" src={project2_img} /></Link>
                            </div>
                            <div className="work-project-detail-explanation">
                                
                            </div>
                        </div>
                        <div className="work-project-detail">
                            <div className="work-project-detail-explanation">

                            </div>
                            <div  className="work-project-detail-img">
                                
                            </div>
                        </div>

                    </div>

                </div>








                <div className="projects-wrapper" style={{display: "none"}}>
                    <div className="image_wrapper">
                        <div className="big_image_layer">
                            <div className="big_image">
                                <img className="big_image_1 lazy" src={project1_img} alt="monkigo" />
                                <div className="about_img_content">
                                    <h3>Heading </h3>
                                    <span>Content</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="image_wrapper_2">
                        <div className="small_image_layer small_image_layer_1">
                                <div className="small_image">
                                    <img className="small_image_1  lazy" src={project_img_3} alt="monkigo" />
                                    <div className="small_about_img_content">
                                        <div className="content">
                                            <h3>Heading </h3>
                                            <span>Content</span>
                                        </div>  
                                    </div>
                                </div>
                        </div>
                        <div className="small_image_layer">
                                <img className=" small_image_2 lazy" src="/assets/projects/project-image-1.jpg" alt="monkigo" />
                                <div className="about_img_content">
                                    <h3>Heading </h3>
                                    <span>Content</span>
                                </div>
                                <div className="small_image">
                                    <div className="text-wrapper"> 
                                        <p><span className="work-project-detail-These">These</span> {data.desc1}</p>
                                        <p>{data.desc2}</p>
                                        <p>{data.desc3}</p>
                                    </div>
                                </div>
                        </div>
                        <div className="small_image_layer small_image_layer_3">
                                <div className="small_image">
                                    <img className=" small_image_3 lazy" src={project_img_4} alt="monkigo" />
                                    <div className="small_about_img_content">
                                        <div className="content">
                                            <h3>Heading </h3>
                                            <span>Content</span>
                                        </div> 
                                    </div>
                                </div>      
                        </div>
                    </div>
                    

                    


                </div>


               

            </div>   
                
        </div> */}
        <section className="wrapper">
            <div className="project-content-wrapper ">
                <div className="mb-five">
                    <div className="project-content mt-three">
                        <div className="left">
                            <div className="over-hide">
                                <h2 className="text-title mt-zero mb-zero">Projects</h2>
                            </div>
                        </div>
                        <div className="right">
                            <div className="experience text-1-5 over-hide">
                                <span className="exp-1">{data.exp1}</span>
                                <span className="exp-2">{data.exp2}</span>
                                <span className="exp-3">{data.exp3}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
         <ProjectRef  move={false}/>  
        <div className="pb-five"></div>
         </>  
    );
}

export default Work_Project;