import React, { useState } from "react";
import './main.css';
import Button from "../../../shared/AboutMeButton/button";
import { Link } from "react-router-dom";
import { Audio } from 'react-loader-spinner';
import axios from "axios";
import toast from 'react-hot-toast';
import Resume from "../../../assets/resume/resume.pdf";
const Contact_Main = () => {
    const [data, setData] = useState({
        content: "One more to step to acheive your goal", 
        content2: "Drop me an E-Mail and we will ",
        content3: "connect.",
        location: "Haryana, India.",
        socials: {
            Resume: Resume,
            Github: "https://github.com/prateek0411999",
            Linkedin: "https://www.linkedin.com/in/prateek-sharma-b97932145/",

        }
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [inputs, setInputs] = useState({
        name: "",
        Organization_name: "",
        email: "",
        message: ""

    });

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
    
    const handleSubmit = event => {
       
        event.preventDefault();
     
        let errors = [];
        errors = [];
        // name validation
        if(inputs.name == "")
        {
            errors.push("Name Must be Present")
        }
        // Organization Name
        if (inputs.Organization_name == "")
        {
            errors.push("Organization Name Must Be Present")
        }
        const emailPattern = /(.+)@(.+){2,}\.(.+){2,}/;
        if (!emailPattern.test(inputs.email)) {
            errors.push("Enter a valid email")
        }
        if (inputs.message == "")
        {
            errors.push("Message Must be Present")
        }
     
        if(errors.length == 0)
        {
            setIsSubmitting(true);
            axios({ url: "https://nodemailer-portfolio-m4ut.onrender.com/send-email",
                method: "POST",
                data: inputs,
            })
            .then((res) => {
                setIsSubmitting(false);
                toast.success('Lets connect soon !!!!');
                // once done reset inputs 
                setInputs({
                    name: "",
                    Organization_name: "",
                    email: "",
                    message: ""
                })
            })
            .catch((err) => {
                console.log(err)
                setIsSubmitting(false);
                toast.war('Something Went Wrong !!!!');
                setInputs({
                    name: "",
                    Organization_name: "",
                    email: "",
                    message: ""
                })
            })
        }else{
            toast.error(errors.join(", "))
        }
        
    
    };
    return(
    
    <div className="wrapper">
        <div className="contact-main-wrapper">
            <div className="section-seperator-top">
                <div className="contact-main-form-detail-wrapper">
                    <div className="contact-main-form">
                        <div className="over-hide">
                            <h1 className="contact-main-form-heading " style={{marginBottom: "0"}}>Let's Work Together ?</h1>
                        </div>
                        <div className="contact-main-form-inputs">
                            <div className="contact-main-input over-hide">
                                <div className="over-hide text-input">
                                    <h6>What is your name?</h6>
                                    <input 
                                        type="text"
                                        name="name"
                                        value={inputs.name}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Your answer here"
                                        required="required"
                                        />
                                </div>
                            </div>
                            <div className="contact-main-input over-hide">
                                <div className="over-hide text-input">
                                    <h6>What is the name of your organisation?</h6>
                                    <input 
                                        type="text" 
                                        name="Organization_name"
                                        value={inputs.Organization_name}
                                        onChange={(e) =>handleChange(e)}
                                        placeholder="Your answer here"
                                        required="required" />
                                </div>
                            </div>
                            <div className="contact-main-input over-hide">
                                <div className="over-hide text-input">
                                    <h6>What is your business email?</h6>
                                    <input 
                                        type="text"
                                        name="email"
                                        value={inputs.email}
                                        onChange={(e) => handleChange(e)} 
                                        placeholder="Your answer here"
                                        required="required"
                                    />
                                 </div>
                            </div>
                            <div className="contact-main-input over-hide">
                                <div className="over-hide text-input">
                                    <h6>Type your message here</h6>
                                    <input 
                                        type="text" 
                                        name="message"
                                        value={inputs.message}
                                        onChange={(e) => handleChange(e)} 
                                        placeholder="Your answer here"
                                        required="required"  />
                                </div>
                            </div>
                        </div>
                        <div className="contact-main-form-submit">
                            <div className="contact-main-form-submit-wrapper">
                                <div className="horizontal-line">
                                    <div className="horizontal-line-wrapper">
                                        <hr className="project-line-separation"/>
                                    </div>
                                
                                </div>
                                <div className="submit-button">
                                    <div className="header_button_wrapper ">
                                        <a onClick={(e) => handleSubmit(e)}  href="#" id="header_button" class="hovered_animated_button cursor_hover_zeroed">
                                            <span class="hovered_animated_button_circle" id="header_button_circle" style={{top:"146.843px",left:"14px"}}></span>
                                            <div class="header_button_inner_content">
                                                <svg width="26" className="button-svg" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.598 2.51465H5.7557V0.764648H25.258L25.258 21.6932H23.508L23.508 4.10635L2.02413 27.2351L0.741943 26.0441L22.598 2.51465Z" fill="black"></path>
                                                </svg>
                                                
                                                {isSubmitting ? <span> Submitting  
                                                <Audio
                                                    height="80"
                                                    width="80"
                                                    radius="9"
                                                    color="black"
                                                    ariaLabel="loading"
                                                    wrapperStyle
                                                    wrapperClass
                                                    /> 
                                                </span> : 
                                                <span className="button-value">Submit</span> }
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-main-detail">
                        <div className="contain-main-detail-wrapper">
                            <div className="contact-detail-content over-hide">
                                <p>{data.content}</p>
                                <p>{data.content2}</p>
                                <p>{data.content3}</p>
                            </div>
                            <div className="contact-detail-location over-hide">
                                <p className="text">Location</p>
                                <p className="location">{data.location}</p>
                            </div>
                            <div className="contact-detail-social over-hide">
                                <p className="text">Social Media-</p>
                                <ul className="over-hide">
                                {Object.keys(data.socials).map((keyname, key)=> (
                                    //data.socials[keyname]
                                    <li>
                                    {(data.socials[keyname].length > 0) ? <a href={data.socials[keyname]} target="_blank" className="footer-wrapper-nav-link-item"><u>{keyname}</u></a> : ""}
                                    </li>
                                    
                                ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}
export default Contact_Main;