import React, { useState } from "react";
import './campus.css';
import Logos from '../../../assets/project_images/ctrlcampus/logos.png';
import Overview from '../../../assets/project_images/ctrlcampus/overview.png';
import AdminWeb from '../../../assets/project_images/ctrlcampus/admin_web.png';
import AdminMobile from '../../../assets/project_images/ctrlcampus/admin_mobile.png';
import StudentMobile from '../../../assets/project_images/ctrlcampus/student_mobile.png';
import StudentWeb from '../../../assets/project_images/ctrlcampus/student_web.png';
import EmailFooter from "../../../shared/footer/email_footer/email_footer";
import BehanceButton from "../../../shared/Button/SeeOnBehance/behance_button";

const Campus = () => {

    const [data, setData] = useState({
        
        company_desc: "MicroArch is a comprehensive application built on a microservices-based architecture, encompassing all vital elements required for a production-grade application.",
        Role: "UX/UI Designer",
        Duration: "4 April 2022- Currently Working",
        Location: "Hyderabad, India",
        intro: "In 2022, I was hired at Ctrl campus as the only UX/UI Designer. I directly Connected with the CEO of the brand and worked with the development team. Design was the most important part as we provided a fully digital product and a good user experience is what mattered here. I worked on various products that we offered.",
        overview: "In 2022, I was hired at Ctrl campus as the only UX/UI Designer. I directly Connected with the CEO of the brand and worked with the development team. Design was the most important part as we provided a fully digital product and a good user experience is what mattered here. I worked on various products that we offered, ",
        admin_web_application: "Admin Web application consists of all the necessary features required by the administraions, organizaers, or organization heads. Features such as tracking attendance, managing fees and salaries, tracking organization count, and modues such as HR, Organizaiton, communication, attendance and many more.",
        admin_mobile_application: "Admin mobile application is similar to the web application. All the tracking features are provided in the mobile application except the once's which require a lot of information and can only be viewed best on a desktop. The mobile app is best for tracking the organization on the go.",
        student_web_application: "Student Web Application is the management software where parent’s or student’s can track their activity. from tracking school fees to tracking grades of the student. Everything can be done through this web application.",
        student_mobile_application: "Admin Web application consists of all the necessary features required by the administraions, organizaers, or organization heads. Features such as tracking attendance, managing fees and salaries, tracking organization count, and modues such as HR, Organizaiton, communication, attendance and many more.",
        live_website_link: "https://ctrlcampus.com/index.html",
        behance_link: "adfa"
    })
    return(<>
        <div className="full-wrapper" >
            <div className="hero-project-container">
                <div className="project-content-wrapper">
                    <div className="pt-six pb-six">
                        <div className="content">
                            <h1 className="text-4">MicroArch</h1>
                            <p className="mt-five text-m"> {data.company_desc}</p>
                            <div className="behance_redirect">
                                <BehanceButton name={"See on Github"} link="https://github.com/prateek0411999/MicroArch" /> 
                            </div>
                        </div>
                        
                    </div>
                    <div>
                    
                </div>
                   
                </div>
                
            </div>
        </div>
        {/* <div className="wrapper">
            <div className="role-duration-location mt-two">
                <div>
                    <p>Role - <span>{data.Role}</span></p>
                </div>
                <div>
                    <p>Duration - <span>{data.Duration}</span></p>
                </div>
                <div>
                    <p>Location - <span>{data.Location}</span></p>
                </div>
            </div>
            
        </div> */}
        <section className="wrapper">
            <div className="campus-introduction">
                <h1 className="text-3">
                    Introduction
                </h1>
                <hr className="project-line-seperation" />
                <p>{data.intro}</p>
                <img src={Logos} alt="LOGO'S"/>
            </div>

            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Overview
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.overview}</p>
                    </div>
                </div>
                
                <img src={Overview} alt="Overview" />
            </div>
            


            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Admin Web Application
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.admin_web_application}</p>
                    </div>
                </div>
                
                <img src={AdminWeb} alt="Admin web" />
            </div>

            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Admin Mobile Application
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.admin_mobile_application}</p>
                    </div>
                </div>
                
                <img src={AdminMobile} alt="AdminMobile " />
            </div>


            <div className="campus-div">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Student Web Application
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.admin_mobile_application}</p>
                    </div>
                </div>
                
                <img src={StudentWeb} alt="StudentWeb " />
            </div>


            <div className="campus-div mb-four">
                <div className="content-wrapper">
                    <div className="left">
                        <h1 className="text-3">
                            Student Mobile Application
                        </h1>
                    </div>
                    <div className="right">
                        <p>{data.admin_mobile_application}</p>
                    </div>
                </div>
                
                <img src={StudentMobile} alt="StudentMobile " />
            </div>

        </section>
        
        <EmailFooter/>

    </>
    )
}
export default Campus;