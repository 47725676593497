import React from "react";

import { Link } from "react-router-dom";
import './behance_button.css'
import { FaGithub } from "react-icons/fa";
const BehanceButton = (props) => {
    return(
        <div className="button-wrapper">
        
            <a href={props.link} id="behance-button" class="hovered_animated_button cursor_hover_zeroed" target="_blank">
                {props.name}   <FaGithub style={{color: "white", marginLeft: "15px"}} scale={2}/>        
            </a>
        
    </div>
    )
}

export default BehanceButton;