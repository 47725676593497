import React from "react";
import './main.css';
import Navbar from "../../shared/Navbar/navbar";
import Hero from "../../components/Hero/hero";
import ProjectRef from "../../components/ProjectRef/projectRef";
import ContactRef from "../../components/ContactRef/contactRef";
import Footer from "../../shared/footer/footer";
import EmailFooter from "../../shared/footer/email_footer/email_footer";
import Calendly from "../../shared/calendly/calendly";

const Main = () => {
    return (
        <div >
            <div className="bg-black border-radius-unique" >
                <Hero />
                <ProjectRef move={true} />
                {/* <ContactRef />    */}
                <Calendly/>
            </div>    
         
            <EmailFooter />
        </div>
    );

}
export default Main;