import React, { useState } from "react";
import './footer.css';
import Resume from '../../assets/resume/resume.pdf';
import { Link } from "react-router-dom";
const Footer = () => {
    const[data, setData] = useState({
        title: "Let's work together!",
        email: "prateekshrmprateek@gmail.com",
        desc1: "Let’s talk about a new",
        desc2: "project or an idea you",
        desc3: "may have!",
          
        socials: {
            Resume: Resume,
            Github: "https://github.com/prateek0411999",
            Linkedin: "https://www.linkedin.com/in/prateek-sharma-b97932145/",
           

        }
    })

    return (
    // <footer className="footer-container" data-scroll-section>
    //     <div className="footer-wrapper ">
    //         <div className="footer-wrapper-v1">
    //             <div>
    //                 <div className="footer-inner-wrapper">
    //                     <div className="footer-inner-title">
    //                         <div className="footer-inner-title-icon">
    //                             <img className="footer-inner-title-icon-img" src={img_icon} />
    //                         </div>
    //                         <h4 className="footer-inner-title-text">{data.title}</h4>


    //                     </div>
    //                     <div className="footer-inner-email-wrapper">
    //                         <div className="footer-inner-email">
    //                             <p>{data.email} </p>
                                
    //                         </div>
    //                     </div>
                        
    //                 </div>
    //                 <hr className="project-line-separation" />

    //                 <div className="footer-wrapper-nav">
    //                     <div className="footer-wrapper-nav-title">
    //                         Social Media -
    //                     </div>
    //                     <div className="footer-wrapper-nav-links">
    //                     {Object.keys(data.socials).map((keyname, key)=> (
    //                             //data.socials[keyname]
    //                             <div>
    //                             {(data.socials[keyname].length > 0) ? <a href="#" className="footer-wrapper-nav-link-item"><u>{keyname}</u></a> : ""}
    //                             </div>
                                
    //                         ))}
    //                     </div>

    //                 </div>
    //             </div>
            
    //         </div>
    //     </div>
    //     <div className="footer-design-develop">
    //         <div className="footer-design-develop-wrapper">
    //                 <div className="footer-designed-by">
    //                     <span className="footer-designed-by-item">Designed By- </span><span className="footer-designed-by-item-val">Ankit Deshwal</span>
    //                 </div>
    //                 <div className="footer-developed-by">
    //                     <span  className="footer-designed-by-item">Developed By- </span><span className="footer-designed-by-item-val">Prateek Sharma</span>
    //                 </div>
    //         </div>
    //     </div>
        
        
    // </footer>

    <div style={{backgroundColor: 'white'}}>
        <footer className="wrapper">

            <div className="footer-horizontal-line">
                <hr className="project-line-separation mb-five mt-five" />
            </div>
            <div className="footer-design-develop">
                <div className="footer-design-develop-wrapper">
                    <div className="footer-designed-by">
                        <span className="designed-by">@2022 </span><span className="ankit">Prateek Sharma</span>
                    </div>
                    <ul className="footer-social-media mt-zero">
                        <span className="social-media">  </span>
                        {Object.keys(data.socials).map((keyname, key)=> (
                                        //data.socials[keyname]
                                        <li>
                                        {(data.socials[keyname].length > 0) ? <a href={data.socials[keyname]} target="_blank" className=""><u>{keyname}</u></a> : ""}
                                        </li>
                                        
                        ))}
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                   
                </div>
            </div>
        </footer>
    </div>
    )
}
export default Footer;