import React, { useState } from "react";
import './hero.css';
import about_hero_img from '../../../assets/images/about_hero.png';
import { ImArrowDownRight2 } from "react-icons/im";
const About_Hero = () => {
    const [data, setData] = useState({
        title1: "I am a UI/UX Designer based in ",
        title2: "INDIA.",
   
        desc1: "My name is Ankit Deshwal, I am a 23 years ",
        desc2: "young designer based in India.",
        desc3: "youtube content creator.",
        areas: [
            {
                name: "UI Design",
                desc: "I have good knowledge on the UI part of design and have a keen eye to details. it helps me creating pixel perfect designs and amazing interfaces."
            },
            { 
                name: "UX Design",
                desc: "I always strive to design amazing experience for the user with zero complexity. The minimal experience can create big impact in the UX of a product."
            }, 
            {
                name: "Design System",
                desc: "Building design from ground zero is one of my specialties as I can have freedom of using my style and approach to design."
            }],
        story: [
            {year: "2017", desc: "I pursued mechanical engineering as my bachelor’s degree. Really into cars, machines and theoretical knowledge. In the first semester itself I fell in love with mechanical design, and drawings."},

            {year: "2018", desc:"I started with photoshop as my additional subject and started learning about graphic design. Later that year I started my part-time job as a graphic designer as well."},

            {year: "2019", desc: "I wanted to continue my career as a graphic designer."},
            {year: "2020", desc: "My friend called me to make an application for him. That was really bad. I got to know about UI/UX Design and found that this is what I am going to do."},
            {year: "2021", desc: "I started learning about design and started studying for my master’s in design. Unfortunately I wasn't able to crack the interviews. "},
            {year: "2022", desc: "I grabbed my first job as a UI/UX Designer and still working as one."}
        ]
    })

    return ( 
        // <div className="wrapper">
        //     <div>
        //         <div className="about-hero-container-wrapper">
        //             <div className="about-hero-wrapper">

        //                 <div className="about-hero-titles">
        //                     <div className="about-hero-title-wrapper">
                                
        //                         <div className="about-hero-title-1">
        //                             <h1 className="about-hero-title-1-text">{data.title1}</h1>
        //                         </div>
        //                         <div className="about-hero-title-2">
        //                             <h1 className="about-hero-title-2-text">{data.title2}</h1>
        //                         </div>
        //                     </div>
        //                     <hr className="project-line-separation"/>
        //                 </div>

        //                 <div className="about-hero-main-desc">
        //                     <div className="about-hero-desc-wrapper">
        //                         <div className="about-hero-desc-content-wrapper">
        //                             <div className="about-hero-desc-content">
        //                                 <div className="about-hero-desc-1">
        //                                 <span className="about-hero-desc-THIS">This</span>  {data.desc1} 
        //                                 </div>
        //                                 <div className="about-hero-desc-2">
        //                                     {data.desc2} 
        //                                 </div>
        //                                 <div className="about-hero-desc-3">
        //                                     {data.desc3} 
        //                                 </div>

        //                                 <div className="about-hero-desc-arrow">
        //                                     <p><ImArrowDownRight2 className="down-right-arrow"/> </p> 
        //                                 </div>
        //                             </div>
                                
        //                         </div>
        //                         <div className="about-hero-desc-img-">
        //                                 <img src={about_hero_img} />
        //                         </div>
        //                     </div>
                        
        //                 </div>
        //             </div>

        //         </div>
                
        //         <div className="about-hero-specialize-wrapper">
        //                 <div className="about-hero-specialize-title">
        //                     <h1>What I specialize in ?</h1>
        //                 </div>
        //                 <div className="about-hero-specialize-areas-wrapper">
        //                     <div className="about-hero-specialize-areas">
        //                         {data.areas.map((element, idx) => (
        //                             <div className={`about-hero-specialize-area`}>
        //                                 <h3>{element.name}</h3>
        //                                 <div className={`about-hero-specialize-area-desc`}>
        //                                     <span> {element.desc}</span>
        //                                 </div>
        //                             </div>
        //                         ))}
                                
        //                     </div>
        //                 </div>  
        //         </div>
        //     </div>
        // </div>

        <>
        <section className="wrapper">
            <div className="about-content-wrapper ">
                <div className="mb-six">
                    <div className="about-content mt-three ">
                        <div className="left">
                            <div className="over-hide">
                                <h2 className="text-title mt-zero mb-zero">About Me</h2>
                            </div>
                        </div>
                        <div className="right">
                            <div className="experience text-1-5 over-hide">
                                <span className="exp-1">{data.desc1}</span>
                                <span className="exp-2">{data.desc2}</span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="wrapper">
            <div className="journey-wrapper">
                <div className="journey over-hide">
                    <div className="left ">
                        <ul >
                            <div className="overhide">
                                <li> My Story</li>
                                <li> Why Design</li>
                                <li> Long Term Goal</li>
                            </div>
                            
                            
                        </ul>
                    </div>
                    <div className="right">
                            <div className="my-story ">
                                <div className="over-hide">
                                    <p className="text-1-5 mt-zero my-story-title over-hide">My Story</p>
                                </div>
                                {data.story.map((item) => (
                                    <div className="journey-item over-hide">
                                        
                                        <p className="text-l over-hide">{item.year} - {item.desc}</p>
                                    </div>   
                                ))}
                            </div>
                            <div className="why-design mt-three">
                                <div className="over-hide">
                                    <p className="text-1-5 why-design-title">Why Design</p>
                                </div>
                                <div className="over-hide">
                                    <p className="text-l design-title">No matter what I tried I wasn’t satisfied with what I was doing. Either be engineering or sports. I wanted something which is different from every other job and which include some creative work. Not sure about what that would be I got my foot into many other stuff, but my search for the dream job never ended.</p>
                                </div>
                                <div className="over-hide">
                                    <p className="text-l mt-two design-title">And this brought me to the place where I am right now. I AM A UI/UX DESIGNER!!</p>
                                </div>
                            </div>
                            <div className="long-term-goal mt-three">
                                <div className="over-hide">
                                    <p className="text-1-5 long-term-goal-title">Long Term Goal</p>
                                </div>
                                <div className="over-hide">
                                    <p className="text-l goal">I don’t see myself doing anything else but design. I am a UX/UI Designer and I will always be one. </p>
                                </div>
                                <div className="over-hide">
                                    <p className="text-l goal">I wanna get into product design and build amazing products, lead design teams, bring a revolution in the design industry and keep evolving. </p>
                                </div>
                            </div>
                    </div>

                </div>
            </div>          

        </section>
        </>
    )
}
export default About_Hero;