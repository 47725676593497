import React, {useEffect} from "react";
import About_Hero from "../../components/About/Hero/hero";
import About_Interest from "../../components/About/Interest/interest";
import EmailFooter from "../../shared/footer/email_footer/email_footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import './about.css';

const About = () =>{
    useEffect(() => {

        let ctx = gsap.context(() => {
    
            const t1 = gsap.timeline();
            
            //hero section
                t1.from(".about-content-wrapper  .about-content .text-title", { 
                
                y: 300,
                ease: "power4.out",
                delay: 0.4,
                duration: 0.5,
                });
                
                t1.from([".about-content-wrapper .exp-1", ".about-content-wrapper .exp-2"], {
                    
                    y: 300,
                    ease: "power4.out",
                    stagger: {
                        amount: 0.4
                    }
                })
                t1.from(".journey-wrapper .journey .left ul  li", {
                    y: 500,
                    ease: "power4.out",
                    stagger: {
                        amount: 0.4
                    }
                })
            
                t1.from([".journey-wrapper .journey .right .my-story .my-story-title", ".journey-wrapper .journey .right  .why-design-title", ".journey-wrapper .journey .right .long-term-goal-title"], {
                    y: 100,
                    ease: "power4.out",
                    stagger: {
                        amount: 0.4
                    }
                })
                t1.from([".journey-item p", ".journey-wrapper .journey .right .design-title", ".journey-wrapper .journey .right  .goal "], {
                    y: 200,
                    ease: "power4.out",
                    stagger: {
                        amount: 0.4
                    }
                }, "<")
                
               
    
            gsap.registerPlugin(ScrollTrigger)
            const t2 = gsap.timeline({
                scrollTrigger: {
                    trigger:".about-interest-wrapper ",
                    start:"top 30%",
                    end: "bottom bottom",
  
                }
            }).from(".about-interest-v1  .img img", {
               y: 1200,
               ease: "power2.out",
               duration: 0.8,
               scale: "0.8",
            })
            t2.from([".hobbies-wrapper .hobbies h4", ".about-interest-content h1", ".hobbies-wrapper .hobbies p", ".about-interest-content p"], {
                y: 400,
                ease: "power4.out",
                delay: 0.1,
                duration: 0.7,
                stagger: {
                    amount: 0.4
                }
            })
           
        })
       
       })
    
    return (
        <div data-scroll-section>
            <About_Hero/>
            <About_Interest/>
            <EmailFooter />
        </div>
    )

}
export default About;