import React, {useEffect} from "react";
import Contact_Main from "../../components/Contact/Main/main";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
const Contact = () => {
    useEffect(() => {

        let ctx = gsap.context(() => {
    
            const t1 = gsap.timeline();
            
            //hero section
                t1.from(".contact-main-wrapper  .contact-main-form h1", { 
                
                y: 300,
                ease: "power4.out",
                delay: 0.2,
                duration: 0.5,
                
                });
                
                t1.from([".contact-main-form-inputs .contact-main-input .text-input"], {
                    
                    y: 300,
                    ease: "power4.out",
                   
                    duration: 0.5,
                    stagger: {
                        amount: 0.4
                    }
                })
                t1.from([".contact-main-detail .contact-detail-content p", ".contain-main-detail-wrapper .contact-detail-location p", ".contain-main-detail-wrapper .contact-detail-social p", ".contain-main-detail-wrapper .contact-detail-social li"],{
                    y: 300,
                    ease: "power4.out",
                    
                    duration: 0.5,
                    stagger: {
                        amount: 0.4
                    }
                })
              
          
           
        })
       
    })
    return (
        <div data-scroll-section>
            <Contact_Main />
        </div>
    )
}
export default Contact;