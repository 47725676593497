import React from "react";
import { InlineWidget } from "react-calendly";
import { Link } from "react-router-dom";

const Calendly = () => {
    return (
      
        <div style={{background: "white", color: "black"}}>
          <div>
            <h2 className="calendly-heading mt-0 text-xxxl" >Ready to push your brand? </h2>
            <p className="calendly-book mt-0"> Book  a call with me</p>
          </div>
          
          <InlineWidget url="https://calendly.com/prateekshrmprateek/discovery-call"  styles={{
            height: '1000px'
          }}/>
        </div>
      );
}
export default Calendly;