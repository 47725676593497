import React, {useEffect} from "react";
import  './work.css'
import Work_Project from "../../components/Work/Projects/project";
import EmailFooter from "../../shared/footer/email_footer/email_footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

const Work = () =>{
    
    useEffect(() => {

        let ctx = gsap.context(() => {
    
            const t1 = gsap.timeline();
            
            //hero section
                t1.from(".project-content-wrapper  .project-content .text-title", { 
                
                y: 300,
                ease: "power4.out",
                delay: 0.4,
                duration: 0.5,
                
                });
                
                t1.from([".project-content-wrapper .exp-1", ".project-content-wrapper .exp-2", ".project-content-wrapper .exp-3"], {
                    
                    y: 300,
                    ease: "power4.out",
                    delay: 0.1,
                    duration: 0.5,
                    stagger: {
                        amount: 0.4
                    }
                })
                
                const t3 = gsap.timeline({
                    scrollTrigger: {
                        trigger:".hero-project-wrapper .section-seperator-bottom",
                        start:"top 30%",
                        end: "bottom bottom",
                       
                       
                    }
                }).from([".hero-project-wrapper .selected-work"], {
                    y: 100,
                    ease: "power4.out",
                    duration: 0.8,
                    stagger: {
                        amount: 0.5
                    }
                   
                })
                t3.from(".hero-project-wrapper  .all-projects .project-item", {
                    y: 200,
                    ease: "power4.out",
                    duration: 1.2,
                    stagger: {
                        amount: 0.5
                    } 
                }, "<")
        
            gsap.registerPlugin(ScrollTrigger)
            const t2 = gsap.timeline({
                scrollTrigger: {
                    trigger:".about-interest-wrapper ",
                    start:"top 30%",
                    end: "bottom bottom",
  
                }
            }).from(".about-interest-v1  .img img", {
               y: 1200,
               ease: "power2.out",
               duration: 0.8,
               scale: "0.8",
            })
            t2.from([".hobbies-wrapper .hobbies h4", ".about-interest-content h1", ".hobbies-wrapper .hobbies p", ".about-interest-content p"], {
                y: 400,
                ease: "power4.out",
                delay: 0.1,
                duration: 0.7,
                stagger: {
                    amount: 0.4
                }
            })
           
        })
       
    })

    return (
        <div >
            <Work_Project />
            <EmailFooter />
        </div>
    );
}
export default Work;