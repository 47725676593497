import React, { useEffect, useRef } from "react";
import { useState } from "react";
import './projectRef.css';
import { ImArrowDownRight2 } from "react-icons/im";
import { FaAngular, FaAws, FaBeer, FaGitlab } from 'react-icons/fa';
import Button from "../../shared/Button/button";
import Project1Img from '../../assets/project_images/MicroArch-draw.png';
import Project2Img from '../../assets/project_images/memories.png';
import Project3Img from '../../assets/project_images/elasticrails.png';
import Clustered_GIF from "../../shared/clusteredGIF/gif";
import {ImArrowUpRight2}from 'react-icons/im';
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { transform } from "framer-motion";
import SmrutiImg from '../../assets/testimonials/smruti.png';
import kuljinderImg from '../../assets/testimonials/kuljinder.png';
import SomannaImg from '../../assets/testimonials/sommana.png';
import SrinivasaImg from '../../assets/testimonials/srinivasa.png'
import Marquee from "react-fast-marquee";
import Card from "../../shared/card/card";

//icons

import { AiFillHtml5 } from "react-icons/ai";
import { SiCss3, SiTypescript, SiRedux, SiHeroku, SiNetlify, SiJenkins, SiSpring, SiCplusplus, SiRubyonrails, SiExpress, SiElasticsearch, SiMysql, SiPostgresql, SiWebpack, SiTailwindcss } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io";
import { DiNodejsSmall, DiJava, DiMysql, DiMongodb, DiPython, DiDjango, DiNpm, DiAngularSimple, DiSass, DiGit, DiAws, DiRuby, DiGrails, DiPostgresql, DiRedis } from "react-icons/di"
import { FaReact, FaDocker, FaBootstrap } from "react-icons/fa";
const size = ['0'];
const backend_icons = [3, 13,7,18,6,22];
const database_icons = [ 1,5, 9, 14,23];
const devops_icons = [ 0, 8, 15, 19 ];
const frontend_icons = [ 2, 4, 10,11, 16,17,21];

 
const ProjectRef = (props)  =>{
    
    let t3 = null;
    const comp = useRef(null);
    const galleryRef = useRef(null);
    let backend_icons_classes = []
    let frontend_icons_classes = []
    let devops_icons_classes = []
    let database_icons_classes = []
    backend_icons.map(e => { backend_icons_classes.push(`.tech_icon_${e}`)})
    frontend_icons.map(e => { frontend_icons_classes.push(`.tech_icon_${e}`)})
    devops_icons.map(e => { devops_icons_classes.push(`.tech_icon_${e}`)})
    database_icons.map(e => { database_icons_classes.push(`.tech_icon_${e}`)})

    useEffect(() => {
        let ctx = gsap.context(() => {
    
            ScrollTrigger.create({
                trigger: ".backend-section",
                start: "top 0%",
                end: "bottom 30%", 
                markers: false,
                
                onEnter: () => {
                    gsap.to(backend_icons_classes, { duration: 1.0, opacity: 1})
                  },
                onLeave: () => {
                    gsap.to(backend_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onLeaveBack: () => {
                    gsap.to(backend_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onEnterBack: () => {
                    gsap.to(backend_icons_classes, { duration: 1.0, opacity: 1})
                }
            })

            ScrollTrigger.create({
                trigger: ".devops-section",
                start: "top 0%",
                end: "bottom 30%", 
                markers: false,
                
                onEnter: () => {
                    gsap.to(devops_icons_classes, { duration: 1.0, opacity: 1})
                  },
                onLeave: () => {
                    gsap.to(devops_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onLeaveBack: () => {
                    gsap.to(devops_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onEnterBack: () => {
                    gsap.to(devops_icons_classes, { duration: 1.0, opacity: 1})
                }
            })

            ScrollTrigger.create({
                trigger: ".database-section",
                start: "top 0%",
                end: "bottom 30%", 
                markers: false,
                
                onEnter: () => {
                    gsap.to(database_icons_classes, { duration: 1.0, opacity: 1})
                  },
                onLeave: () => {
                    gsap.to(database_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onLeaveBack: () => {
                    gsap.to(database_icons_classes, {duration: 1.0, opacity: 0.2})
                },
                onEnterBack: () => {
                    gsap.to(database_icons_classes, { duration: 1.0, opacity: 1})
                }
            })

            ScrollTrigger.create({
                trigger: ".frontend-section",
                start: "top 0%",
                end: "bottom 30%", 
                markers: false,
            
                onEnter: () => {
                    gsap.to(frontend_icons_classes, { duration: 1.0, opacity: 1})
                  },
                onLeave: () => {
                    gsap.to(frontend_icons_classes, { duration: 1.0, opacity: 0.2})
                },
                onLeaveBack: () => {
                    gsap.to(frontend_icons_classes, { duration: 1.0, opacity: 0.2})
                },
                onEnterBack: () => {
                    gsap.to(frontend_icons_classes, { duration: 1.0, opacity: 1})
                }
            })



            gsap.registerPlugin(ScrollTrigger)

             t3 = gsap.timeline({
                scrollTrigger: {
                    trigger:".hero-project-wrapper .section-seperator-top",
                    start:"top 30%",
                    end: "bottom bottom",
                    markers: false
                }
            })
            // }).from([".hero-project-wrapper .section-seperator-top .title-description p", ".hero-project-wrapper .section-seperator-top .content-number .selected-text-desc",  ".hero-project-wrapper .section-seperator-top .content-number .number p" ], {
            //     opacity: 0,
            //     ease: "power4.out",
            //     duration: 0.1,
            //     stagger: {
            //         amount: 0.2
            //     }
               
            // })
            t3.from(".all-projects .project-item", {
                opacity: 0,
                ease: "power4.out",
                duration: 0.7,
                stagger: {
                    amount: 0.5
                },
                
                // filter: "blur(5px)"
            })
    
            
        })
       
       }, [])

    useEffect(() => {
        let menuItem = document.querySelectorAll(".image-hover-container");
        let menuImage = document.querySelectorAll(".project__card__image");
        
        let items = ['25deg', '-25deg'];
        
        // adding eventListeners to all the menuItems.
        for (let i = 0; i < 3; i++) {
          //   image reveal animation
          let randomIndex = Math.floor(Math.random() * items.length);
          let selectedRandomItem = items[randomIndex];
          const animation = gsap.fromTo(menuImage[i], {
            opacity: 0,
            duration: 0.26,
            scale: 0,
            ease: "ease-in-out",
            transform: `rotate(${selectedRandomItem})`
          }, {
            opacity: 1,
            duration: 0.26,
            scale: 1,
            transform: "rotate(0deg)"
          });
        
          menuItem[i].addEventListener("mouseenter", () => animation.play());
          menuItem[i].addEventListener("mouseleave", () => animation.reverse());
        
          //   initialization
          animation.reverse();
        }
        
        //to move image along with cursor
        function moveText(e) {
            let top_value = 0;
            gsap.to([...menuImage], {
                css: {
                //transform: `translate(${e.clientX}px -100px, ${e.clientY}px)`
                //top: e.clientY,
                // top: e.clientY % 20,
                left: e.clientX - 400,
                //filter: `brightness(${e.clientX})`
                skew: e.clientX / 10,
                },
                duration: 0.3,
            });
        }
        
        menuItem.forEach((el) => {
          el.addEventListener("mousemove", moveText);
        });
    }, [])

    useEffect(() => {
        

        const ctx = gsap.context(() => {
            const details = gsap.utils.toArray(
              ".desktopContentSection:not(:first-child)"
            );
            const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)");
      
            gsap.set(photos, { yPercent: 101 });
      
            const allPhotos = gsap.utils.toArray(".desktopPhoto");
      
            let mm = gsap.matchMedia();

            // create
            ScrollTrigger.create({
                trigger: ".gallery",
                start:"top 30%",
                end:"bottom 40%", 
                markers: false,
                
                onEnter: () => {
                    gsap.to('.gallery', { duration: 1.0, backgroundColor: '#FAFAFA'})
                    gsap.to(['.desktopContent h1 ', '.desktopContent p '], { duration: 1.0, color: '#000000'})
                  },
                onLeave: () => {
                    gsap.to('.gallery', { duration: 1.0, backgroundColor: '#000000'})
                },
                onLeaveBack: () => {
                    gsap.to('.gallery', { duration: 1.0, backgroundColor: '#000000'})
                },
                onEnterBack: () => {
                    gsap.to('.gallery', { duration: 1.0, backgroundColor: '#FAFAFA'})
                    gsap.to(['.desktopContent h1 ', '.desktopContent p '], { duration: 1.0, color: '#000000'})
                }
                
            });

            // ScrollTrigger.create({
            //     trigger: ".gallery",
            //     start:"bottom 30%",
            //     end:"bottom 0%", 
            //     markers: true,
                
            //     onEnter: () => {
            //         console.log("adfadsfasd adfadsf")
            //         gsap.to('.gallery', { duration: 1.0, backgroundColor: '#FAFAFA'})
            //         gsap.to(['.desktopContent h1 ', '.desktopContent p '], { duration: 1.0, color: '#000000'})
            //       },
            //     onLeave: () => {
            //         gsap.to('.gallery', { duration: 1.0, backgroundColor: '#000000'})
            //     },
            //     onLeaveBack: () => {
            //         console.log("!!!!!!!!!!!!")
            //         gsap.to('.gallery', { duration: 1.0, backgroundColor: '#000000'})
            //     },
                
            // });
      
            // add a media query. When it matches, the associated function will run
            // mm.add("(min-width: 600px)", () => {
            //   // this setup code only runs when viewport is at least 600px wide
            //   console.log("desktop");
      
              ScrollTrigger.create({
                trigger: galleryRef.current,
                start: "top top",
                end: "bottom bottom",
                pin: ".right"
              });
      
              //create scroll trigger for each details section
              //trigger photo animation when headline of each details section
              //reaches 80% of window height
            //   details.forEach((detail, index) => {
            //     let headline = detail.querySelector("h1");
      
            //     let animation = gsap
            //       .timeline()
            //       .to(photos[index], { yPercent: 0 })
            //       .set(allPhotos[index], { autoAlpha: 0 });
      
            //     ScrollTrigger.create({
            //       trigger: headline,
            //       start: "top 80%",
            //       end: "top 50%",
            //       animation: animation,
            //       scrub: true,
            //       markers: false
            //     });
            //   });
      
            //   return () => {
            //     // optional
            //     // custom cleanup code here (runs when it STOPS matching)
            //     console.log("mobile");
            //   };
            // });
          }, comp);
      
          return () => {
            ctx.revert();
          };
        }, []);


    const [data, setData] = useState({
        desc1: "I create design solutions and user experience. Join hands",
        desc2: "to make your brand stand out.",
        trait1: "Creative, passionate, and unique",
        trait2: "are some of my key qualities which",
        trait3: "make me stand out among other",
        trait4: "designers.",
        projects: [{
            company_type: "Saas Brand",
            name: "MicroArch",
            description: ["Ctrl campus is a <span> SAAS brand </span> focusing on", "building ERP Products to help organizations", "digitalize their campuses." ],
            for: "High-level Design, Microservices Arch",
            link: "",
            link_name: "View Case Study",
            image_url: Project1Img,
            slash: "/22",
            link_to: "https://github.com/prateek0411999/MicroArch",
            class_name: "ctrl-campus-image",
            bg_img: Project1Img
        }, 
        {
            company_type: "",
            name: "SearchScape",
            description: ["A UX case study in collaboration with NIWWRD. I", "worked on multiple products to solve UX issues",  "in the website."],
            for: "Rails Marvel with Elastic Search and Kibana Brilliance.",
            link: "",
            link_name: "View Case Study",
            image_url: Project3Img,
            slash: "/23",
            link_to: "https://github.com/prateek0411999/elastic_rails",
            class_name: "niwwrd-image",
            bg_img: Project3Img
        },
          
        {
            company_type: "",
            name: "Memories",
            description: ["I worked on this project to research about", "swiggies after ordering user experience. I faced", "a small issue and wanted to address it."],
            for: "Social platform for sharing life moments and events.",
            link: "",
            link_name: "View Case Study",
            image_url: Project2Img,
            slash: "/24",
            link_to: "https://memories.prateeksharma.dev/",
            class_name: "swiggy-image",
            bg_img: Project2Img
        }
        ],
        moveDesc1: "I have a water like seamless way of getting things",
        moveDesc2: "done. A clear and transparent workflow makes me",
        moveDesc3: "work better and with ease.",
        selected_work: "Ever since I discovered the magic of coding, I've been on a wild quest to create apps that are as innovative as they are practical. Drawing upon my 3+ years of experience, I dive into new technologies like a kid in a candy store. And when it comes to startups, I've navigated their rollercoaster ride with the enthusiasm of a seasoned thrill-seeker – call me the startup whisperer.",
        testimonials: [{
            title: "Co-Founder @Ctrlcampus",
            image: SrinivasaImg,
            name: "Srinivasa Reddy",
            description: "Ankit Deshwal , continues to be an invaluable member of our team, consistently delivering exceptional UI/UX work. Ankit's dedication to their craft, attention to detail, and collaborative spirit make them an asset to any project. I highly recommend Ankit's for their ongoing contributions."
        }, {
            title: "Soft Skill Trainer",
            image: kuljinderImg,
            name: "Kuljinder Kaur",
            description: "Ankit is a creative, enthusiastic, and highly disciplined student. He ensured timely submissions of any given task with efforts and energy. He has the zeal to learn and grow which helps him improve continuously. His vision for future is clear and he is dedicated to achieving his goals. I am sure he will do wonders in life with his innovative perspective and out of box thinking. All the best Ankit."
        }, {
            title: "Smruti Tiwari",
            image: SmrutiImg,
            name: "UI/UX Designer @",
            description: "Ankit is an exceptional UI/UX designer with a strong grasp of design principles. While I haven't worked with him directly, but his work is extremely amazing. He is dedicated to create designs that are user-centric. Ankit's input undeniably enhances the work quality. He provided me with valuable guidance in the past, highlighting his excellent mentoring abilities. I look forward to the chance to work closely with him, He's exactly the kind of person you want on your team, he is genuine and always open to learn."
        }, {
            title: "Product Designer",
            image: SomannaImg,
            name: "Somanna B U",
            description: "Ankit is a highly skilled UX/UI Designer with a passion for creating user-centered designs. His YouTube videos on design are informative and engaging. I highly recommend him for any UX/UI Design position. He's very competent and trustworthy and there's a lot more coming from him in the future."
        },
       
        
    ]
        
    })
    
    const [skill, setSkill] = useState({
        showHide: [false, false, false, false]
    })
    
    const revealSkill = (value) => {
        let reveal_state_array = skill.showHide
       
        reveal_state_array[value] = !reveal_state_array[value]
        
        setSkill({showHide: reveal_state_array})
    }

    

    return(
        // <section className="project-ref-container "> 
            
        //     <div className="project-ref-wrapper">
                            
        //                     {data.projects.map((element, idx) => (
                                
        //                         <div className={`project-ref-project ${((idx%2) == 0 ) ? "background-grey" : "" }   `}>
        //                             <div className="project-ref-project-wrapper  even-spacing">
        //                                 <div className="project-ref-content-wrapper">
                                    
        //                                     <div className={`project-ref-project-${idx}`}>
        //                                         <div className="project-ref-project-details-wrapper">
                                                    
        //                                             <div className="project-ref-project-name text-m">
        //                                                 <p className="company-type grey-color" >{element.company_type} </p>
        //                                                 {/* {element.desc.length? <span className="project-ref-project-desc"> &nbsp;({element.desc})</span> : ""} */}
        //                                                 <h1 className="project-ref-project-name-heading text-xxl">{element.name} </h1>
        //                                                 <div className="project-description-wrapper">
        //                                                     {element.description.map((desc, idx) => (
        //                                                         <p className="project-small-desc">{desc}</p>
        //                                                     ))}
        //                                                 </div>
        //                                                 <div className="project-redirect-link">
        //                                                     <a  href={element.link} target="_blank">{element.link_name} <span className=""><ImArrowUpRight2 /></span></a>
        //                                                 </div>
                                                        

        //                                             </div>
                                                   
        //                                         </div>
                                                
        //                                     </div>
        //                                 </div>
        //                                 <div className="project-ref-image">
        //                                     <img src={element.image_url} />
                                            
        //                                 </div>
        //                             </div>
        //                         </div>
                            
                                
        //                     ))}
        //     </div>      
                        
        //     <Clustered_GIF />
           
        // </section>
        <>
        <div style={{borderRadius: "0px 0px 20px 20px"}}>
            <section className="wrapper" >
                <div className="hero-project-wrapper ">
                    <div className="section-seperator-top pt-five ">
                        <div className="over-hide">
                            <hr className="mb-one mt-two project-line-separation"/>
                            <div className="common-flex-layout over-hide mb-three">
                                
                                <div className="title-description over-hide">
                                    <p className="mt-zero mb-zero text-xxl heading-color over-hide"> Selected Work  </p>
                                    <p className="selected-text text-s over-hide">Spinning backend magic at Metafic, making code flex like a heavyweight champ! </p>
                                </div>
                                <div className="content-number">
                                    {/* <Button link="/work" name="My Projects" />
                                    */}
                                    <div className="content-number-wrapper">
                                        <div className="content">
                                            <p className="selected-text-desc text-1-5">{data.selected_work}</p>
                                        </div>
                                        <div className="number">
                                            <p className="text-1">(03)</p>
                                        </div>
                                    </div>
                                    
                                
                                </div>
                            </div>
                            <div className="selected-work over-hide">
                                
                                
                            </div>
                        </div>
                        <hr className="mb-one project-line-separation"/>

                        <div className="all-projects">
                            {data.projects.map((item) => (
                                <a style={{color: "white"}}  href={item.link_to? item.link_to : "/ctrl-campus"} target="_blank">
                                <div className=" image-hover-container ">
                                    <div className="project-item ">
                                        <div className="project">
                                            <h2 className="text-5-5 mb-zero mt-zero">{item.name} </h2>
                                            <p className="mb-zero mt-zero">{item.for}</p>
                                        </div>
                                        <div className="slash">
                                            <p className="text-xxxl">{item.slash}  <span className="current">{item.name == "CtrlCampus" ? "- Currently" : ""}</span></p>
                                        </div>
                                        <div className={item.class_name + " project__card"}>
                                            <div className="project__card__inner">
                                                <div className="project__card__image" style={{backgroundImage: `url(${item.bg_img})`, opacity: 0}}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <hr className="mb-one  project-line-separation"/>
                                </a>
                            ))}

                        </div>

                        <div className="want-to-see-more">
                            <a className="text-2-5" href="https://github.com/prateek0411999" target="_blank"> Want to see more? </a> 
                        </div>
                    </div>
                </div>

            </section>

            {props.move ? 
            <section className="wrapper" >
                <div className="hero-move-contain">
                    <div className="hero-move  ">
                    
                        <div className="common-flex-layout over-hide mb-three">
                            
                            <div className="title-description">
                                <p className="mt-zero mb-zero text-xxl heading-color"> My Speciality  </p>
                            </div>
                            <div className="content-number">
                                {/* <Button link="/work" name="My Projects" />
                                    */}
                                    <div className="content-number-wrapper">
                                    <div className="content">
                                        <p className="selected-text-desc text-1-5">I'm the backend magician, conjuring up robust systems with multiple backend frameworks. My frontend finesse? Well, let's just say I make interfaces that won't make your eyes scream.  Oh, and I've got a knack for DevOps too—keeping things so smooth, the servers might just start singing.</p>
                                    </div>
                                    <div className="number">
                                        <p className="text-1">(03)</p>
                                    </div>
                                    </div>
                                
                                
                            </div>
                        </div>
                        <div className="hero-move-wrapper">
                            
                            <div className="move-left">
                                <div className="move-left-wrapper">
                                    {/* <div className="over-hide">
                                        <h2 className="text-5-5 mt-zero mb-zero">How I move.</h2>
                                    </div>
                                    <div className="over-hide mt-two">
                                        <span className="mt-zero mb-zero text-l move-desc-1">{data.moveDesc1} </span>
                                        <span className="mt-zero mb-zero text-l move-desc-2">{data.moveDesc2}</span>
                                        <span className="mt-zero mb-zero text-l move-desc-3">{data.moveDesc3}</span>
                                    </div> */}
                                    <svg width="754" height="302" viewBox="0 0 754 302" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMaxYMax meet">
                                        <path className="path-2" d="M-54 272.448V57.9466C-54 42.4858 -47.6603 27.7012 -36.4604 17.0429V17.0429C-14.6575 -3.70557 19.5876 -3.70557 41.3905 17.0429V17.0429C52.5905 27.7012 58.9301 42.4858 58.9301 57.9466V251.021C58.9301 264.703 64.5404 277.787 74.4519 287.219V287.219C93.7465 305.58 124.052 305.58 143.347 287.219V287.219C153.258 277.787 158.868 264.703 158.868 251.021V57.9466C158.868 42.4858 165.208 27.7012 176.408 17.0429V17.0429C198.211 -3.70557 232.456 -3.70557 254.259 17.0429V17.0429C265.459 27.7012 271.798 42.4858 271.798 57.9466V251.021C271.798 264.703 277.409 277.787 287.32 287.219V287.219C306.615 305.58 336.92 305.58 356.215 287.219V287.219C366.126 277.787 371.737 264.703 371.737 251.021V57.9466C371.737 42.4858 378.076 27.7012 389.276 17.0429V17.0429C411.079 -3.70557 445.324 -3.70557 467.127 17.0429V17.0429C478.327 27.7012 484.667 42.4858 484.667 57.9466V251.021C484.667 264.703 490.277 277.787 500.188 287.219V287.219C519.483 305.58 549.788 305.58 569.083 287.219V287.219C578.994 277.787 584.605 264.703 584.605 251.021V57.9466C584.605 42.4858 590.944 27.7012 602.144 17.0429V17.0429C623.947 -3.70557 658.192 -3.70557 679.995 17.0429V17.0429C691.195 27.7012 697.535 42.4858 697.535 57.9466V115.647C697.535 146.832 722.815 172.112 754 172.112V172.112" stroke="#4B4B4B"/>
                                    </svg>

                                </div>
                            </div>
                            <div className="move-right">
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 01. </span>
                                                <span className="text-xxl"> Architectural Planning </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-1"  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(0)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={skill.showHide[0] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p className="text-1">Involves defining the high-level structure, components, and interactions of a system to ensure efficient, scalable, and maintainable software design.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 02. </span>
                                                <span className="text-xxl"> Database Design </span>
                                            </div>
                                            <div className="svg">
                                                <svg  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(1)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path id="path-2" fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                

                                    <div className={skill.showHide[1] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p className="text-1"> Involves intricately crafting the database schema, relationships, and indexing strategies to optimize data organization, retrieval efficiency, and overall system performance, aligning seamlessly with application-specific demands</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 03. </span>
                                                <span className="text-xxl"> Implementation </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-3" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(2)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                

                                    <div className={skill.showHide[2] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p className="text-1">This is the phase that involves translating the meticulously planned software architecture or database design into executable code, ensuring the practical realization of the envisioned structure and functionality.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                                <div className="move-skill">
                                    <div className="over-hide">
                                        <div className="skill">
                                            <div>
                                                <span className="text-xxl pr-one"> 04. </span>
                                                <span className="text-xxl"> Deployment & Maintainence </span>
                                            </div>
                                            <div className="svg">
                                                <svg id="svg-4" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="dropdown__icon" onClick={() => revealSkill(3)}>
                                                    <path fill="currentColor" d="M0 8h16v1H0z"></path>
                                                    <path fill="currentColor" d="M9 0v16H8V0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={skill.showHide[3] ? "skill-reveal reveal": "skill-reveal"}>
                                        <p className="text-1"> Involves  systematic release and configuration of the developed software into a live environment & ongoing tasks such as updates, bug fixes, and optimizations to ensure sustained performance, security, and adaptability in response to changing requirements.</p>
                                    </div>
                                    <hr className="mb-one mt-two project-line-separation"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            : <></>}
            {props.move ?
            <></>
            // <section className="testimonial section-seperator-bottom">
            //     <hr className="mb-three mt-two project-line-separation"/>
            //     <div className="wrapper">
            //         <div className="common-flex-layout over-hide mb-three">
                                    
            //             <div className="title-description">
            //                 <p className="mt-zero mb-zero text-xxl heading-color"> Testimonials  </p>
            //             </div>
            //             <div className="content-number">
            //                 {/* <Button link="/work" name="My Projects" />
            //                 */}
            //                 <div className="content-number-wrapper">
            //                     <div className="content">
            //                         <p className="selected-text-desc text-1-5">I let my work do the talking. With a diverse portfolio of independently managed projects, I've garnered feedback that speaks to the quality and impact of my design solutions. Discover what others have to say about my work.</p>
            //                     </div>
            //                     <div className="number">
            //                         <p>(04)</p>
            //                     </div>
            //                 </div>
                            
                        
            //             </div>
            //         </div>
            //         <p className="selected-text-desc text-1-5 mb-three">What people have to say about me !!</p>         
            //     </div>                   
              
            //     <div className="testimonial-cards-wrapper">
                    
            //             <div className="cards">
            //                 <Marquee
            //                  autoFill="true"
            //                  speed={90}
            //                  pauseOnHover="true"
            //                  >
            //                     {data.testimonials.map((e) => (
            //                         <Card title={e.title} description={e.description} image={e.image} name={e.name} />
            //                     ))}
            //                 </Marquee>

            //             </div>
                    
            //     </div>  
              

            // </section>
            : <></>}

            <div ref={comp}>
           
                <div ref={galleryRef} className="gallery">
                    <div className="left">
                        <div className="desktopContent">
                            <div className="backend-section">
                                <div className="desktopContentSection ">
                                    <h1 className="backend-heading text-3">My Backend Arsenal: Crafting Digital Magic with</h1>
                                    <div>
                                        <ul>
                                            <li className="technology-name text-1-5">Ruby on Rails </li>
                                            <li className="technology-name text-1-5">Java (SpringBoot)</li>
                                            <li className="technology-name text-1-5">Node Js (Express Js)</li>
                                        </ul>
                                        <p className="text-1-5">"These technologies aren't just tools; they're the brushes with which I paint digital landscapes, each offering unique capabilities and possibilities."</p>
                                        <div className="mobile-icons">
                                            <div >
                                          
                                                <i className= {` tech_icon`} aria-hidden="true"> <DiJava /></i>
                                                <i className= {` tech_icon`} aria-hidden="true"> <SiSpring /></i>
                                                <i className= {` tech_icon`} aria-hidden="true"> <SiRubyonrails /></i>
                                                <i className= {` tech_icon`} aria-hidden="true"> <DiRuby /></i>
                                                <i className= {` tech_icon`} aria-hidden="true"> <DiNodejsSmall /></i>
                                                <i className= {` tech_icon`} aria-hidden="true"> <SiExpress /></i>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="database-section">
                                <div className="desktopContentSection">
                                    <h1 className="text-3">Navigating Data Realms with My Database Toolkit</h1>
                                    <div>
                                        <ul>
                                            <li className="technology-name text-1-5">PostgreSQL </li>
                                            <li className="technology-name text-1-5">MySQL</li>
                                            <li className="technology-name text-1-5">MongoDB</li>
                                            <li className="technology-name text-1-5">Elastic Search</li>
                                            <li className="technology-name text-1-5">Redis</li>
                                        </ul>
                                        <p className="text-1-5">"These technologies aren't just tools; they're the brushes with which I paint digital landscapes, each offering unique capabilities and possibilities."</p>
                                        <div className="mobile-icons">
                                            <i className= {` tech_icon`} aria-hidden="true"> <DiMongodb /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <DiMysql /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiPostgresql /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiElasticsearch /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <DiRedis /></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="devops-section">
                                <div className="desktopContentSection ">
                                    <h1 className="text-3">Embracing DevOps with My Favorite Tools:</h1>
                                    <div>
                                        <ul>
                        
                                            <li className="technology-name text-1-5">Gitlab CI/CD</li>
                                            <li className="technology-name text-1-5">Docker</li>
                                            <li className="technology-name text-1-5">AWS</li>
                                            <li className="technology-name text-1-5">Heroku</li>
                                            
                                        </ul>
                                        <p className="text-1-5">I've always been captivated by the world of DevOps and Kubernetes is my next step in my ongoing passion for learning DevOps. </p>
                                        <div className="mobile-icons">
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiHeroku /></i> 
                                            <i className= {` tech_icon`} aria-hidden="true"> <FaGitlab /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <FaAws /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <FaDocker /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <DiGit /></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frontend-section">
                                <div className="desktopContentSection ">
                                    <h1 className="text-3">Crafting User Experiences with My Frontend Palette</h1>
                                    <div>
                                        <ul>
                        
                                            <li className="technology-name text-1-5">JavaScript / TypeScript</li>
                                            <li className="technology-name text-1-5">React</li>
                                            <li className="technology-name text-1-5">Angular</li>
                                            <li className="technology-name text-1-5">Css3 / Tailwind Css</li>
                                            <li className="technology-name text-1-5">Webpack</li>
                                            
                                        </ul>
                                        <p className="text-1-5"> </p>
                                        <div className="mobile-icons">
                                        
                                          
                                            <i className= {` tech_icon`} aria-hidden="true"> <FaReact /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiWebpack /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiTypescript /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <FaAngular /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <SiTailwindcss /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <IoLogoJavascript /></i>
                                            <i className= {` tech_icon`} aria-hidden="true"> <AiFillHtml5 /></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                    <div className="right">

                        <div className="desktopPhotos">
                            <div className="desktopPhoto red">
                                
                                <div className="icons">
                                 
                           
                                            <i className= {`tech_icon_0 tech_icon`} aria-hidden="true"> <SiHeroku /></i>
                                            <i className= {`tech_icon_1 tech_icon`} aria-hidden="true"> <DiMongodb /></i>
                                            <i className= {`tech_icon_2 tech_icon`} aria-hidden="true"> <FaReact /></i>
                                            <i className= {`tech_icon_3 tech_icon`} aria-hidden="true"> <DiJava /></i>
                                            <i className= {`tech_icon_4 tech_icon`} aria-hidden="true"> <SiWebpack /></i>
                                            <i className= {`tech_icon_5 tech_icon`} aria-hidden="true"> <DiMysql /></i>
                                            <i className= {`tech_icon_6 tech_icon`} aria-hidden="true"> <DiNodejsSmall /></i>
                                            <i className= {`tech_icon_7 tech_icon`} aria-hidden="true"> <SiRubyonrails /></i>
                                            <i className= {`tech_icon_8 tech_icon`} aria-hidden="true"> <FaGitlab /></i>
                                            <i className= {`tech_icon_9 tech_icon`} aria-hidden="true"> <SiPostgresql /></i>
                                            <i className= {`tech_icon_10 tech_icon`} aria-hidden="true"> <SiTypescript /></i>
                                            <i className= {`tech_icon_11 tech_icon`} aria-hidden="true"> <FaAngular /></i>
                                            <i className= {`tech_icon_12 tech_icon`} aria-hidden="true"></i>
                                            <i className= {`tech_icon_13 tech_icon`} aria-hidden="true"> <SiSpring /></i>
                                            <i className= {`tech_icon_14 tech_icon`} aria-hidden="true"> <SiElasticsearch /></i>
                                            <i className= {`tech_icon_15 tech_icon`} aria-hidden="true"> <FaAws /></i>
                                            <i className= {`tech_icon_16 tech_icon`} aria-hidden="true"> <SiTailwindcss /></i>
                                            <i className= {`tech_icon_17 tech_icon`} aria-hidden="true"> <IoLogoJavascript /></i>
                                            <i className= {`tech_icon_18 tech_icon`} aria-hidden="true"> <DiRuby /></i>
                                            <i className= {`tech_icon_19 tech_icon`} aria-hidden="true"> <DiGit /></i>
                                            <i className= {`tech_icon_20 tech_icon`} aria-hidden="true"> <FaDocker /></i>
                                            <i className= {`tech_icon_21 tech_icon`} aria-hidden="true"> <AiFillHtml5 /></i>
                                            <i className= {`tech_icon_22 tech_icon`} aria-hidden="true"> <SiExpress /></i>
                                            <i className= {`tech_icon_23 tech_icon`} aria-hidden="true"> <DiRedis /></i>



                                     
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>


                </div>
        </div>
        
        </>
    );
    
}
export default ProjectRef;
