import React from "react";
import './interest.css'
import { useState } from "react";
import interest_image from '../../../assets/images/interest.png'
const About_Interest = () =>{
    const [data, setData] =  useState({
        desc1: "I love photography, coffee, and gadgets. If you are planning my birthday gift. You know what to gift me. hahaha.",
        desc2: "And stationery as well!!!",
        desc3: "If you are planning my birthday gift. You know what to gift me. hahaha."
    })
    return (
        <div className="wrapper">
            <div className="about-interest-wrapper ">
                <div className="section-seperator-top section-seperator-bottom">
                    <div className="about-interest-v1">
                        <div className="hobbies-wrapper">
                            <div className="hobbies over-hide">
                                <div className="over-hide">
                                    <h4 className="text-xxl">My Hobbies</h4>
                                </div>
                                <p className="mt-zero mb-zero over-hide">I like watching thriller movies and some tv series as well. I </p>
                                <p className="mt-zero over-hide">workout and and sometimes I play sports as well.</p>

                                <p className=" mb-zero over-hide">I make Youtube videos on design as well. (We are over 1.5k </p>
                                <p className="mt-zero mb-zero over-hide">subs already.)</p>
                            </div>
                        </div>
                        <div className="about-interest img over-hide">
                            <img className="over-hide" src={interest_image} />
                        </div>
                        <div className="about-interest-content-wrapper">
                            <div className="about-interest-content">
                                <div className="over-hide">
                                    <h1 className="mt-zero over-hide"> My Interest</h1>
                                </div>
                                <div className="about-interest-content-desc-1 over-hide">
                                    <p>{data.desc1}</p>
                                    
                                </div>
                                <div  className="about-interest-content-desc-2 over-hide">
                                    <p>{data.desc2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}
export default About_Interest;